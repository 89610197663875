<template>
  <div class="mt-3">
    <p class="mb-0"><b>{{ $t('addedNotificationContacts') }}</b></p>
    <div
      class="mb-0 badge badge-primary badge-sm"
      v-for="user in users"
      :key="user">{{ user }}
        <modal-wrapper>
          <template v-slot="{ close }">
            <confirmation-modal
              :close="close"
              :content="$t('confirmDeleteSandboxContact')"
              @confirm="deleteContact(user)"
            ></confirmation-modal>
          </template>
          <template v-slot:trigger="{ open }">
            <button
              class="badge badge-primary feather icon-x close"
              :disabled="isDesabled"
              @click.prevent="open"
            >&nbsp;</button>
          </template>
        </modal-wrapper>
    </div>
    <div class="server-error-message content mt-3" v-if="errorMessage.length">
      {{ errorMessage }}
      <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
    </div>
  </div>
  <div class="mt-3">
    <DynamicForm
        :schema="formSchema"
        :button-data="submitButton"
        :is-disabled="isLoading"
        form-class="justify-content-start"
        @submit="submit"
      >
        <template v-slot:error>
          <div class="server-error-message content mt-3" v-if="errorMessage.length">
            {{ errorMessage }}
            <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
          </div>
        </template>
    </DynamicForm>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import DynamicForm from './DynamicForm.vue'
import { useNotificationSettingsLogic } from '@/application/composables/notificationSettingsLogic.js'

export default {
  name: 'AddNotificationRecipientForm',
  components: {
    DynamicForm,
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  props: {
    users: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  emits: ['send-form', 'delete-contact'],
  setup(props, { emit }) {
    const {
      formSchema,
      submitButton,
      errorMessage,
    } = useNotificationSettingsLogic()

    return {
      formSchema,
      submitButton,
      errorMessage,
      submit: (data, { resetForm }) => {
        if (!props.users.includes(data.email)) {
          emit('send-form', data, resetForm)
        }
      },
      deleteContact: (contact) => {
        emit('delete-contact', contact)
      },
    }
  },
}
</script>
